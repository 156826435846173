/* eslint-disable */
import React from 'react';
import { makeStyles, Typography, ListItem,  ListItemText, Collapse, List, Chip, Avatar } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import NavItem from './../NavItem';


const useStyles = makeStyles((theme) => ({
    collapseIcon: {
        fontSize: '1rem',
    },
    listIcon: {
        minWidth: '25px',
    },
    listItem: {
        borderRadius: '0px',
        marginBottom: '0px',
        marginTop: '5px',
    },
    subMenuCaption: {
        ...theme.typography.subMenuCaption,
    },
    listItemNoBack: {
        backgroundColor: 'transparent !important',
        paddingTop: '8px',
        paddingBottom: '8px',
        borderRadius: '5px',
    },
    errorChip: {
        color: theme.palette.error.main,
        backgroundColor: '#ffcdd2',
        marginRight: '5px',
    },
}));


const NavCollapse = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const { menu, level } = props;
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(null);

    const handleClick = () => {

        // const adiv = document.getElementById("account");
        // if (menu.id !== "account" && adiv.classList.contains('Mui-selected')) {
        //     document.getElementById("account").click();
        // }


        // if (menu.id === "account") {
        //     let path = `/accountbox`;
        //     history.push(path);
        // }
        // else if (menu.id === "transactions") {
        //     let path = `/Transactions`;
        //     history.push(path);
        // }
        // else if (menu.id === "profile") {
        //     let path = `/Profilebox`;
        //     history.push(path);
        // }
        // else if (menu.id === "reports") {
        //     let path = `/Specificaccountstatement`;
        //     history.push(path);
        // }
        // else if (menu.id === "message") {
        //     let path = `/Message`;
        //     history.push(path);
        // }


        const adiv = document.getElementById("account");
        if (menu.id !== "account" && adiv.classList.contains('Mui-selected')) {
            document.getElementById("account").click();
        }
        const bdiv = document.getElementById("transactions");
        if (menu.id !== "transactions" && bdiv.classList.contains('Mui-selected')) {
            document.getElementById("transactions").click();
        }
        const cdiv = document.getElementById("reports");
        if (menu.id !== "reports" && cdiv.classList.contains('Mui-selected')) {
            document.getElementById("reports").click();
        }
        const ddiv = document.getElementById("profile");
        if (menu.id !== "profile" && ddiv.classList.contains('Mui-selected')) {
            document.getElementById("profile").click();
        }
        // const ediv = document.getElementById("helpcenter");
        // if (menu.id !== "helpcenter" && ediv.classList.contains('Mui-selected')) {
        //     document.getElementById("helpcenter").click();
        // }


        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    const menus = menu.children.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon className={classes.listCustomIcon} />
    ) : (
        <ArrowForwardIcon className={classes.listCustomIcon} fontSize={level > 0 ? 'inherit' : 'default'} />
    );

    let menuIconClass = !menu.icon ? classes.listIcon : classes.menuIcon;

    return (
        <React.Fragment>
            <ListItem
                id={menu.id}
                className={level > 1 ? classes.listItemNoBack : classes.listItem}
                selected={selected === menu.id}
                button
                onClick={handleClick}
                style={{ paddingLeft: level * 16 + 'px' }}
            >
                <img className={menuIconClass} src={menuIcon.type} alt=""/>
                {/* <ListItemIcon className={menuIconClass}>{menuIcon}</ListItemIcon> */}
                <ListItemText
                    primary={
                        <Typography
                            variant={selected === menu.id ? 'subtitle1' : 'body1'}
                            color="inherit"
                            className={classes.listItemTypography}
                        >
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {menu.chip && (
                    <Chip
                        className={menu.chip.error && classes.errorChip}
                        color={menu.chip.color}
                        variant={menu.chip.variant}
                        size={menu.chip.size}
                        label={menu.chip.label}
                        avatar={menu.chip.avatar && <Avatar>{menu.chip.avatar}</Avatar>}
                    />
                )}
                {open ? <ExpandLess className={classes.collapseIcon} /> : <ExpandMore className={classes.collapseIcon} />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {menus}
                </List>
            </Collapse>
        </React.Fragment>
    );
};

export default NavCollapse;
