/* eslint-disable */
import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { Box, Grid, TextField, makeStyles, IconButton, Hidden, Badge, Tooltip, Select, Button } from '@material-ui/core';
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import { useSelector, useDispatch } from 'react-redux';
import Customization from './Customization';
import ProfileSection from './ProfileSection';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import Snackbars from '../../../views/Utils/Snackbars';
import CryptoJS from 'crypto-js';
// import NotificationsNoneTwoToneIcon from '@material-ui/icons/NotificationsNoneTwoTone';
import logo from './../../../assets/images/logo-new.svg';
import logo_black from './../../../assets/images/Logo-black-new.png';
import logo_white from './../../../assets/images/Logo-white-new.png';
import logo_common from './../../../assets/images/new-common-logo.png';
import { drawerWidth } from './../../../store/constant';
import * as actionTypes from './../../../store/actions';
import $ from 'jquery';
import MenuItem from '@material-ui/core/MenuItem';
import flag1 from '../../../assets/images/Flag1.png';
import flag2 from '../../../assets/images/Flag2.png';
import flag3 from '../../../assets/images/Flag3.png';
import flag4 from '../../../assets/images/Flag4.jpg';
import flag5 from '../../../assets/images/Flag5.png';
import flag6 from '../../../assets/images/Flag6.png';
import flag7 from '../../../assets/images/Flag7.png';
import flag8 from '../../../assets/images/Flag8.png';
import i18next from 'i18next';


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1.25),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuIcon: {
        fontSize: '1.5rem',
    },
    iconspace: {
        marginLeft: '0px',
        fontSize: '26px'
    }
}));


const Header = ({drawerToggle, onLanguageChange}) => {
    // const { drawerToggle } = props;
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success_msg, setSuccessMsg] = useState("");
    const [auth_enabled, set_auth_enabled] = React.useState(true);
    const concustomization = useSelector((state) => state.customization);


    const [unread, setUnread] = useState(0);
    const handleLoadUnReadNews = (BroadcastLength, UserDataArr) => {
        const headers = {
            'Content-Type': 'application/json',
            'bcbqtoken': process.env.REACT_APP_SECRET_TOKEN,
            'accesstoken': 'Bearer ' + window.localStorage.getItem("accesstoken"),
        }
        axios.get(process.env.REACT_APP_API_URL + 'user/v1/unreadnews/' + window.localStorage.getItem("u_id"), {
                headers: headers
            })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    var bytes = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_JWT_USER_SEC);
                    var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                    var counter = 0;
                    originalText.forEach(originalText_ => {
                        let date1 = new Date(UserDataArr.created_datetime).getTime();
                        let date2 = new Date(originalText_.created_datetime).getTime();
                        if (date1 < date2) {
                            counter++;
                        }
                    });
                    setUnread(counter+BroadcastLength);
                } else {
                    setUnread(BroadcastLength);
                    if (res.message === 'Access Token Not Match!') {
                        localStorage.removeItem("temp_u_id");
                        localStorage.removeItem("temp_accesstoken");
                        localStorage.removeItem("u_id");
                        localStorage.removeItem("accesstoken");
                        localStorage.removeItem("full_name");
                        localStorage.removeItem("company_name");
                        localStorage.removeItem("customizationNavType");
                        localStorage.removeItem("file_path");
                        localStorage.removeItem("user_image");
                        localStorage.clear();
                        history.push('/login');
                    }
                    // setError(true);
                    // setErrorMsg(res.message);
                    // setTimeout(() => {
                    //     setError(false);
                    // }, 500);
                }
            })
            .catch(function (error) {
                setUnread(BroadcastLength);
                // setError(true);
                // setErrorMsg(error);
                // setTimeout(() => {
                //     setError(false);
                // }, 500);
            });
    }
    const handleLoadUnReadBroadcast = (UserDataArr) => {
        const headers = {
            'Content-Type': 'application/json',
            'bcbqtoken': process.env.REACT_APP_SECRET_TOKEN,
            'accesstoken': 'Bearer ' + window.localStorage.getItem("accesstoken"),
        }
        axios.get(process.env.REACT_APP_API_URL + 'user/v1/unreadbroadcast/' + window.localStorage.getItem("u_id"), {
                headers: headers
            })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    var bytes = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_JWT_USER_SEC);
                    var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                    var counter = 0;
                    originalText.forEach(originalText_ => {
                        let date1 = new Date(UserDataArr.created_datetime).getTime();
                        let date2 = new Date(originalText_.created_datetime).getTime();
                        if ((originalText_.bc_id === 1 || (originalText_.bc_id !== 1 && date1 < date2) && originalText_.to_send === "All" || (originalText_.to_send === "User" && originalText_.toid === parseInt(window.localStorage.getItem("u_id"))))) {
                            counter++;
                        }
                    });
                    handleLoadUnReadNews(counter, UserDataArr);
                } else {
                    setUnread(0);
                    if (res.message === 'Access Token Not Match!') {
                        localStorage.removeItem("temp_u_id");
                        localStorage.removeItem("temp_accesstoken");
                        localStorage.removeItem("u_id");
                        localStorage.removeItem("accesstoken");
                        localStorage.removeItem("full_name");
                        localStorage.removeItem("company_name");
                        localStorage.removeItem("customizationNavType");
                        localStorage.removeItem("file_path");
                        localStorage.removeItem("user_image");
                        localStorage.clear();
                        history.push('/login');
                    }
                    // setError(true);
                    // setErrorMsg(res.message);
                    // setTimeout(() => {
                    //     setError(false);
                    // }, 500);
                }
            })
            .catch(function (error) {
                setUnread(0);
                // setError(true);
                // setErrorMsg(error);
                // setTimeout(() => {
                //     setError(false);
                // }, 500);
            });
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return false;
    }
    
    const handleLoadAutoLogout = () => {
        const headers = {
            'Content-Type': 'application/json',
            'bcbqtoken': process.env.REACT_APP_SECRET_TOKEN,
            'accesstoken': 'Bearer ' + window.localStorage.getItem("accesstoken"),
        }
        axios.get(process.env.REACT_APP_API_URL + 'user/v1/viewautologoutbcm/1', {
                headers: headers
            })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    var bytes = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_JWT_USER_SEC);
                    var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                    if (originalText.status === 'true' && getCookie('autologoutonetime') === false && getCookie('autologout') === false) {
                        var date = new Date();
                        date.setTime(date.getTime() + (parseInt(originalText.logout_timeout) * 60 * 1000));
                        document.cookie = "autologout=set; expires=" + date.toGMTString() + "; path=/";

                        date = new Date();
                        date.setDate(date.getDate() + 1);
                        document.cookie = "autologoutonetime=set; expires=" + date.toGMTString() + "; path=/";
                        console.log('login');
                    }
                    else if (getCookie('autologoutonetime') !== false && getCookie('autologout') === false) {
                        alert(originalText.logout_message);
                        console.log('logout');

                        date = new Date();
                        date.setDate(date.getDate() - 2);
                        document.cookie = "autologoutonetime=set; expires=" + date.toGMTString() + "; path=/";

                        window.localStorage.removeItem("temp_u_id");
                        window.localStorage.removeItem("temp_accesstoken");
                        window.localStorage.removeItem("u_id");
                        window.localStorage.removeItem("accesstoken");
                        // window.localStorage.clear();
                        history.push('/login');
                    }
                } else {
                    // setError(true);
                    // setErrorMsg(res.message);
                    // setTimeout(() => {
                    //     setError(false);
                    // }, 500);
                }
            })
            .catch(function (error) {
                // setError(true);
                // setErrorMsg(error);
                // setTimeout(() => {
                //     setError(false);
                // }, 500);
            });
    }

    const handleLoadGetUserInfo = () => {
        const headers = {
            'Content-Type': 'application/json',
            'bcbqtoken': process.env.REACT_APP_SECRET_TOKEN,
            'accesstoken': 'Bearer ' + window.localStorage.getItem("accesstoken"),
        }
        axios.get(process.env.REACT_APP_API_URL + 'user/v1/viewuser/' + window.localStorage.getItem("u_id"), {
                headers: headers
            })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    var bytes = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_JWT_USER_SEC);
                    var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                    window.localStorage.removeItem("user_menu_hide");
                    if(originalText.old_level_number !== 0 && originalText.auth_enabled === 'false') {
                        window.localStorage.setItem("user_menu_hide", 'true');
                        window.localStorage.setItem("user_menu_hide", 'true');
                        history.push('/TwoSecurity');
                    }

                    handleLoadUnReadBroadcast(originalText);
                } else {
                    // setError(true);
                    // setErrorMsg(res.message);
                    // setTimeout(() => {
                    //     setError(false);
                    // }, 500);
                }
            })
            .catch(function (error) {
                // setError(true);
                // setErrorMsg(error);
                // setTimeout(() => {
                //     setError(false);
                // }, 500);
            });
    }

    const handleLoadCustomizationNavType = () => {
        var customizationNavType = localStorage.getItem("customizationNavType");
        if(customizationNavType === "light") {
            dispatch({ type: actionTypes.THEME_VALUE, navType: "light" });
        } else{
            dispatch({ type: actionTypes.THEME_VALUE, navType: "dark" });
        }
    }
    
    const [SelectedLanguage, setSelectedLanguage] = useState("en");
    const handleChangeSelectedLanguage = (event) => {
        i18next.changeLanguage(event.target.value)
        setSelectedLanguage(event.target.value);
        onLanguageChange(event.target.value);
        window.localStorage.setItem("language", event.target.value);
    }


    useEffect(() => {
        if (window.localStorage.getItem("language") !== null ) {
            setSelectedLanguage(window.localStorage.getItem("language"))
            i18next.changeLanguage(window.localStorage.getItem("language"))
        }
        else{
            i18next.changeLanguage(SelectedLanguage)
            window.localStorage.setItem("language", SelectedLanguage);
        }
          
        handleLoadCustomizationNavType();

        if (window.localStorage.getItem("temp_u_id") !== null)
        set_auth_enabled(false);

        handleLoadGetUserInfo();
        
        // if (sessionStorage.getItem("blackbox_chatbox_user") === "null" || sessionStorage.getItem("blackbox_chatbox_user") === null || sessionStorage.getItem("blackbox_chatbox_user") === undefined) {
        //     const script = document.createElement("script");
        //     script.id = "blackbox";
        //     script.type = "text/javascript";
        //     script.src = "https://9310slzh0k5k.statuspage.io/embed/script.js";
        //     document.head.appendChild(script);
        //     sessionStorage.setItem("blackbox_chatbox_user", true);
        // }

        const frame = document.createElement('iframe');
        frame.src = 'https://9310slzh0k5k.statuspage.io/embed/frame';
        frame.style.position = 'fixed';
        frame.style.display = 'block';
        frame.style.border = 'none';
        frame.style.boxShadow = '0 20px 32px -8px rgba(9,20,66,0.25)';
        frame.style.zIndex = '9999';
        frame.style.transition = 'left 1s ease, bottom 1s ease, right 1s ease';

        frame.title = 'Bitcoinmat Status';
        frame.ariaHidden = true;

        var mobile;
        if (mobile = window.screen.width < 450) {
            frame.src += '?mobile=true';
            frame.style.height = '20vh';
            frame.style.width = '100vw';
            frame.style.left = '-9999px';
            frame.style.bottom = '-9999px';
            frame.style.transition = 'bottom 1s ease';
        } else {
            frame.style.height = '115px';
            frame.style.width = '320px';
            frame.style.left = '60px';
            // frame.style.right = 'auto';
            frame.style.bottom = '60px';
        }

        document.body.appendChild(frame);

        var actions = {
            showFrame: function() {
                if (mobile) {
                    frame.style.left = '0';
                    frame.style.bottom = '0';
                }
                else {
                    frame.style.left = '60px';
                    // frame.style.right = 'auto'
                }
            },
            dismissFrame: function(){
                if(window.sessionStorage.getItem('blackbox_chatbox') === "true") {
                    frame.style.left = '-9999px';
                }
            }
        }

        window.addEventListener('message', function(event){
            if (event.data.action && actions.hasOwnProperty(event.data.action)) {
                if (window.sessionStorage) {
                    actions[event.data.action](event.data);
                    if (!window.sessionStorage.getItem('blackbox_chatbox')) {
                        window.sessionStorage.setItem('blackbox_chatbox', "true");
                    }
                }
            }
        }, false);

        setInterval(() => handleLoadGetUserInfo(), 5000);
        setInterval(() => handleLoadAutoLogout(), 5000);


        const locationPathnameArr = window.location.pathname.split("/");
        const currentPath = locationPathnameArr[1];
        const routesArr = [
            'kiosk',
            'home',
            'terms',
            'privacy',
            'pricing',
            'help_center',
            'buy_online',
            'sell_online',
            'crypto_voucher',
            'crypto_delivery',
            'crypto_to_crypto_exchange',
            'bitkasa',
            'cash_withdrawals_bitcoin_atms',
            'bitcoin_atms',
            'bitcoin_atms_details',
            'bitcoinmat',
            'bitcoin_atms_details_new',
            'crypto-to-crypto',
            'crypto-to-crypto-success',
            'bitcoin_base',
            'crypto_merch',
            'transaction_check',
            'crypto_rates',
            'blog_page',
            'blogdetails',
            'they-wrote-about-us',
            'they-wrote-about-us-details',
            'login',
            'loginemailverify',
            'loginphoneverify',
            'register',
            'register',
            'forgetpassword',
            'forgetchangepassword',
            'forgetgoogleauthenticator',
            'verify2faotp',
            'jiranote',
            'otp',
            'authenabled',
            'googleauthenticator',
            'auth',
            'completeregistration',
            'invoice',
            'invoicewithdraw',
            'userinfo',
            'summary',
            'dashboard',
            'cardmanagement',
            'createcard',
            'editcard',
            'viewcard',
            'editprofile',
            'changepassword',
            'twosecurity',
            'transactions',
            'transferbetweenaccounts',
            'transferbetweenusers',
            'incomingwiretransfer',
            'cardfundingtransfer',
            'outgoingwiretransfer',
            'pricing',
            'transactions_box',
            'transactions_crypto_exchange',
            'transactions_cash_order',
            'transactions_crypto_earning',
            'transactions_crypto_deposit',
            'transactions_crypto_withdraw',
            'transactions_crypto_credit',
            'account',
            'accountdetails',
            'accountboxes',
            'accountboxes_credit_account',
            'accountboxes_crypto_account',
            'accountboxes_earning_account',
            'accountboxes_earning_account_staking',
            'accountboxes_earning_account_purchasing',
            'accountboxes_cash_account',
            'dashboard_new',
            'jiramessages',
            'createissue',
            'openissue',
            'news',
            'newsdetailss',
            'specificaccountstatement',
            'allaccountstransactions',
            'allaccountsbalances',
            'kyc',
            'settings',
            'files',
            'transferdetails',
            'transfersuccessfully',
            'incomingmessage',
            'outgoingmessage',
            'readmessage',
            'newmessage',
            'profilebox',
            'message',
            'accountbox',
            'messages',
            'messagedetails',
            'map',
        ];
        const isInRoutesArr = routesArr.includes(currentPath.toLowerCase());
        if(!isInRoutesArr) {
            dispatch({ type: actionTypes.THEME_VALUE, navType: "light" });
            localStorage.setItem("customizationNavType", "light");
            history.push("/Home");
        }
    }, [])
    
    
    return (
        <React.Fragment>
            {error?<Snackbars open_close={error} type="error" message={error_msg}/>:""}
            {success?<Snackbars open_close={success} type="success" message={success_msg}/>:""}
            {/* <iframe src={process.env.REACT_APP_APP_URL+"bitcoinmat-status.php"} title="Bitcoinmat Status" className='external-iframe'></iframe> */}
            <Box className='logo' width={drawerWidth}>
                <Grid container justify="space-between" alignItems="center">
                    <Hidden smDown>
                        <Grid item component={Link} to="/Home">
                            {/* {concustomization.navType === "light" ?
                                <Box mt={0.5}>
                                    <img src={logo_black} alt="Logo" width={110} />
                                </Box>
                                :
                                <Box mt={0.5}>
                                    <img src={logo_white} alt="Logo" width={110} />
                                </Box>
                            } */}
                            <Tooltip arrow title={i18next.t('btc_beta')}>
                                <Box mt={0.5}>
                                    <img src={logo_common} alt="Logo" width={110} />
                                </Box>
                            </Tooltip>
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={drawerToggle}
                        >
                            <MenuTwoToneIcon className={classes.menuIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <div className={classes.grow} />
    

            <Button className='btn-user active-btn homelang'>
                <TextField id="simple-menu" select fullWidth value={SelectedLanguage} onChange={(event) => handleChangeSelectedLanguage(event)} variant="outlined" >
                                            <MenuItem value="en"> <img className='flag' src={flag1} alt="" /> </MenuItem>
                                            <MenuItem value="sl"> <img className='flag' src={flag3} alt="" /> </MenuItem>
                                            <MenuItem value="cz"> <img className='flag' src={flag2} alt="" /> </MenuItem>
                                            <MenuItem value="gr"> <img className='flag' src={flag8} alt="" /> </MenuItem>
                                            <MenuItem value="sp"> <img className='flag' src={flag4} alt="" /> </MenuItem>
                                            <MenuItem value="rus"> <img className='flag' src={flag5} alt="" /> </MenuItem>
                                            <MenuItem value="grm"> <img className='flag' src={flag6} alt="" /> </MenuItem>
                                            <MenuItem value="hg"> <img className='flag' src={flag7} alt="" /> </MenuItem>
                </TextField>
            </Button>


            {/* <SearchSection theme="light" /> */}
            <Customization />
            {/* <Tooltip title='Notification'>
                <Badge badgeContent={4} color="error">
                    <NotificationsNoneTwoToneIcon className={classes.menuIcon}></NotificationsNoneTwoToneIcon>
                </Badge>
            </Tooltip> */}
            
            {auth_enabled ?
            <Tooltip title={i18next.t('header_messages')} className="link-header" component={Link} to='/messages'>
                <Badge badgeContent={unread} color="error">
                    <EmailOutlined className={classes.iconspace} />
                </Badge>
            </Tooltip>
            : '' }


            <ProfileSection />

        </React.Fragment>
    );
};

export default Header;