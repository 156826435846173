/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import { Box, Tooltip, makeStyles, useMediaQuery, useTheme, Divider, Drawer, Grid, Hidden, Typography, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import MenuList from './MenuList';
import { Link, useHistory } from 'react-router-dom';
import { ExternalLink } from 'react-external-link';
import logo from './../../../assets/images/logo-new.svg';
import userpic from './../../../assets/images/users/maskot-head.png';
import { drawerWidth } from './../../../store/constant';
import downarrow from '../../../assets/images/Arrow-down.png';
import downarrowwhite from '../../../assets/images/Arrow-down-white.png';
import uparrow from '../../../assets/images/Arrow-up.png';
import uparrowwhite from '../../../assets/images/Arrow-up-white.png';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import logo_common from './../../../assets/images/new-common-logo.png';
import i18next from 'i18next';

import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },

    toolbar: theme.mixins.toolbar,
    // logoContainer: {
    //     lineHeight: 0,
    //     background: theme.palette.primary.main,
    //     boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    // },
    logoContainer: { background: '#DC9018' }
    , drawerPaper: {
        width: drawerWidth,
        background: theme.palette.common.black,
        color: theme.palette.text.primary,
    },
    drawerPaperLight: {
        width: drawerWidth,
        borderRight: 'none',
        boxShadow: '0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15)',
        top: '64px',
        [theme.breakpoints.down('sm')]: {
            top: 0,
        },
    },
    menuCaption: {
        ...theme.typography.menuCaption,
    },
    ScrollHeight: {
        height: 'calc(100vh - 65px)',
        padding: '10px',
    },
    name: {
        fontSize: '18px',
        marginTop: '15px',
        textAlign: 'center',
        fontWeight: '600',
        width: '100%'
    }
}));


const MainLayout = (props) => {

    const handleClick = () => {
        // Call drawerToggle function when the button is clicked
        drawerToggle();

        // You can also pass the function to the onClose prop if needed
        // onClose={drawerToggle}
    };

    // const [btype,setBtype] = useState(['Company L.L.C. 1','Company L.L.C. 2']);
    const handlechange = (value) => {
        // setBtype(value);
    }
    const { drawerOpen, drawerToggle, window } = props;
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const concustomization = useSelector((state) => state.customization);
    const [user_name] = React.useState(props.name.split(",")[0]);
    const [company_name] = React.useState(props.name.split(",")[1]);
    const [file_path] = React.useState(props.name.split(",")[2]);
    const [user_image] = React.useState(props.name.split(",")[3]);
    const [user_menu_hide, set_user_menu_hide] = React.useState(true);


    const [dropdown, set_dropdown] = React.useState(true);
    const clickDropdown = () => {
        if (dropdown === true)
            set_dropdown(false);
        else
            set_dropdown(true);
    }

    const [MenuName, setMenuName] = useState("");
    const location = useLocation();

    const handleAccounts = (MenuNameVal) => {
        setMenuName(MenuNameVal);
        history.push('/Accountboxes');
    }
    const handleTransactions = (MenuNameVal) => {
        setMenuName(MenuNameVal);
        history.push('/Transactions_box');
    }

    const [IsMobileDevice, setIsMobileDevice] = useState(false);
    const isMobileDevice = () => {
        setIsMobileDevice(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }

    useEffect(() => {
        isMobileDevice();

        if (localStorage.getItem("afterloginlanguage") !== null) {
            // setSelectedLanguage(window.localStorage.getItem("language"))
            i18next.changeLanguage(localStorage.getItem("afterloginlanguage"))
        }
        var menuNameTemp = location.pathname;
        menuNameTemp = menuNameTemp.replace('/', '');
        setMenuName(menuNameTemp);

        if (localStorage.getItem("user_menu_hide") === 'true') {
            set_user_menu_hide(false);
        }
    }, []);


    const drawer = (
        <React.Fragment>
            <Hidden mdUp>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    elevation={5}
                    alignItems="center"
                    spacing={0}
                    className={[classes.toolbar, classes.logoContainer].join(' ')}
                >
                    <Grid item>
                        <Tooltip arrow title='This is Beta, please consider put big amount, we are testing this website, in some hack or any case, your funds can be lost.'>
                            <Box mt={0.5}>
                                <img src={logo_common} alt="Logo" width={110} />
                                <MenuIcon className='menu-mobie' onClick={handleClick} />
                            </Box>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Hidden>
            {concustomization.navType === "light" ?
                <PerfectScrollbar className={classes.ScrollHeight} style={{ background: '#CCCCCC' }}>

                    <Grid className="userbox" style={{ background: '#fff', boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)' }}>
                        {user_image !== "" && user_image !== 'null' ?
                            <img alt="Logo" src={process.env.REACT_APP_API_URL + file_path + user_image} width={55} height={55} />
                            :
                            <img alt="Logo" src={userpic} width={55} />
                        }
                        <Typography component={Button} onClick={clickDropdown} className={classes.name}>{i18next.t('my_user_profile')}<img className='arrow' alt={dropdown === true ? "Up Arrow" : "Down Arrow"} src={dropdown === true ? uparrow : downarrow} /></Typography>
                        {dropdown === true ?
                            <Grid className='listbox'>
                                <Typography className='active'>{user_name}</Typography>
                            </Grid>
                            : ''}
                    </Grid>

                    {user_menu_hide ?
                        <Grid className="NewCstm_menu light-mode">
                            <div className="contenedor-menu">
                                <ul className="menu">
                                    <li className={MenuName === 'dashboard' ? 'active dashboard' : 'dashboard'} onClick={(event) => { setMenuName("dashboard"); if (IsMobileDevice) { drawerToggle } }} ><Link to="/Dashboard_new">{i18next.t('dashboard')}</Link></li>
                                    <li className={MenuName === 'accounts' ? 'active accounts submenu' : 'accounts submenu'} onClick={(event) => setMenuName("accounts")}><Link to="/Accountboxes">{i18next.t('accounts')}</Link>
                                        <ul>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Accountboxes_credit_account">{i18next.t('credit_acc')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Accountboxes_crypto_account">{i18next.t('crypto_acc')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Accountboxes_earning_account">{i18next.t('earning_acc')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Accountboxes_cash_account">{i18next.t('cash_acc')}</Link></li>
                                            {/* <li onClick={IsMobileDevice?drawerToggle:() => {}}><Link to="/Dashboard_new">New Dashboard</Link></li> */}
                                        </ul>
                                    </li>
                                    <li className={MenuName === 'transactions' ? 'active transactions submenu' : 'transactions submenu'} onClick={(event) => setMenuName("transactions")}><Link to="/Transactions_box">{i18next.t('trans')}</Link>
                                        <ul>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_crypto_exchange">{i18next.t('crypto_exch')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_cash_order">{i18next.t('cash_order')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_crypto_earning">{i18next.t('crypto_earn')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_crypto_deposit">{i18next.t('crypto_depo')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_crypto_withdraw">{i18next.t('crypto_withdraw')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_crypto_credit">{i18next.t('credit')}</Link></li>
                                        </ul>
                                    </li>
                                    <li className={MenuName === 'administrator' ? 'active administrator submenu' : 'administrator submenu'} onClick={(event) => setMenuName("administrator")}><Link href="#">{i18next.t('my_profile')}</Link>
                                        <ul>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Summary">{i18next.t('summary')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Editprofile">{i18next.t('edit_profile')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Kyc">{i18next.t('verifi_level')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/changepassword">{i18next.t('change_pass')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/TwoSecurity">{i18next.t('security')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Settings">{i18next.t('settings')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Pricing">{i18next.t('sidebar_pricing')}</Link></li>
                                            {/* <li onClick={IsMobileDevice?drawerToggle:() => {}}><Link to="/Files">Files</Link></li> */}
                                        </ul>
                                    </li>
                                    <li className={MenuName === 'helpcenter' ? 'active helpcenter submenu' : 'helpcenter submenu'} onClick={(event) => setMenuName("helpcenter")}><Link href="#">{i18next.t('help')}</Link>
                                        <ul>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><ExternalLink href="https://calendly.com/bitcoinmat_org/30min" target="_blank">{i18next.t('arrange_meet')}</ExternalLink></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><ExternalLink href="https://bitcoinmat.atlassian.net/wiki/spaces/ATMS/overview" target="_blank">{i18next.t('user_faq')}</ExternalLink></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><ExternalLink href="https://bitcoinmat.atlassian.net/servicedesk/customer/portal/8" target="_blank">{i18next.t('sidebar_contact')}</ExternalLink></li>
                                            {/* <li onClick={IsMobileDevice?drawerToggle:() => {}}><Link to="#">Pricing</Link></li> */}
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><ExternalLink href="https://bitcoinmat.atlassian.net/servicedesk/customer/portal/8" target="_blank">{i18next.t('sidebar_claim_form')}</ExternalLink></li>
                                            {/* <li onClick={IsMobileDevice?drawerToggle:() => {}}><Link to="/Files" target="_blank">Files</Link></li> */}
                                        </ul>
                                    </li>
                                    <li className={MenuName === 'messages' ? 'active messages submenu' : 'messages submenu'} onClick={(event) => setMenuName("messages")}><Link href="#">{i18next.t('sidebar_messages')}</Link>
                                        <ul>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/messages">{i18next.t('sidebar_messages')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/News">{i18next.t('news')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><ExternalLink href="https://bitcoinmat.atlassian.net/servicedesk/customer/portal/8" target="_blank" >{i18next.t('ticket')}</ExternalLink></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                        : ''}

                </PerfectScrollbar>

                :
                <PerfectScrollbar className={classes.ScrollHeight} style={{ background: '#272727' }}>

                    <Grid className="userbox" style={{ background: '#000', boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)' }}>
                        {user_image !== "" && user_image !== 'null' ?
                            <img alt="Logo" src={process.env.REACT_APP_API_URL + file_path + user_image} width={55} height={55} />
                            :
                            <img alt="Logo" src={userpic} width={55} />
                        }
                        <Typography component={Button} onClick={clickDropdown} className={classes.name}>My user profile  <img className='arrow' alt={dropdown === true ? "Up Arrow" : "Down Arrow"} src={dropdown === true ? uparrowwhite : downarrowwhite} /></Typography>
                        {dropdown === true ?
                            <Grid className='listbox'>
                                <Typography className='active'>{user_name}</Typography>
                            </Grid>
                            : ''}
                    </Grid>

                    {user_menu_hide ?
                        <Grid className="NewCstm_menu dark-mode">
                            <div className="contenedor-menu">
                                <ul className="menu">
                                    <li className={MenuName === 'dashboard' ? 'active dashboard' : 'dashboard'} onClick={(event) => { setMenuName("dashboard"); if (IsMobileDevice) { drawerToggle } }}><Link to="/Dashboard_new">{i18next.t('dashboard')}</Link></li>
                                    <li className={MenuName === 'accounts' ? 'active accounts submenu' : 'accounts submenu'} onClick={(event) => setMenuName("accounts")}><Link href="#">{i18next.t('accounts')}</Link>
                                        <ul>
                                            {/* <li onClick={IsMobileDevice?drawerToggle:() => {}}><Link to="/Accountboxes">Account</Link></li> */}
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Accountboxes_credit_account">{i18next.t('credit_acc')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Accountboxes_crypto_account">{i18next.t('crypto_acc')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Accountboxes_earning_account">{i18next.t('earning_acc')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Accountboxes_cash_account">{i18next.t('cash_acc')}</Link></li>
                                            {/* <li onClick={IsMobileDevice?drawerToggle:() => {}}><Link to="/Dashboard_new">New Dashboard</Link></li> */}
                                        </ul>
                                    </li>
                                    <li className={MenuName === 'transactions' ? 'active transactions submenu' : 'transactions submenu'} onClick={(event) => setMenuName("transactions")}><Link href="#">{i18next.t('trans')}</Link>
                                        <ul>
                                            {/* <li onClick={IsMobileDevice?drawerToggle:() => {}}><Link to="/Transactions_box">Transactions</Link></li> */}
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_crypto_exchange">{i18next.t('crypto_exch')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_cash_order">{i18next.t('cash_order')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_crypto_earning">{i18next.t('crypto_earn')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_crypto_deposit">{i18next.t('crypto_depo')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_crypto_withdraw">{i18next.t('crypto_withdraw')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Transactions_crypto_credit">{i18next.t('credit')}</Link></li>

                                        </ul>
                                    </li>
                                    <li className={MenuName === 'administrator' ? 'active administrator submenu' : 'administrator submenu'} onClick={(event) => setMenuName("administrator")}><Link href="#">{i18next.t('my_profile')}</Link>
                                        <ul>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Summary">{i18next.t('summary')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Editprofile">{i18next.t('edit_profile')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Kyc">{i18next.t('verifi_level')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/changepassword">{i18next.t('change_pass')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/TwoSecurity">{i18next.t('security')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Settings">{i18next.t('settings')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/Pricing">{i18next.t('sidebar_pricing')}</Link></li>
                                            {/* <li onClick={IsMobileDevice?drawerToggle:() => {}}><Link to="/Files">Files</Link></li> */}
                                        </ul>
                                    </li>
                                    <li className={MenuName === 'helpcenter' ? 'active helpcenter submenu' : 'helpcenter submenu'} onClick={(event) => setMenuName("helpcenter")}><Link href="#">{i18next.t('help')}</Link>
                                        <ul>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><ExternalLink href="https://calendly.com/bitcoinmat_org/30min" target="_blank">{i18next.t('arrange_meet')}</ExternalLink></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><ExternalLink href="https://bitcoinmat.atlassian.net/wiki/spaces/ATMS/overview" target="_blank">{i18next.t('user_faq')}</ExternalLink></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><ExternalLink href="https://bitcoinmat.atlassian.net/servicedesk/customer/portal/8" target="_blank">{i18next.t('sidebar_contact')}</ExternalLink></li>
                                            {/* <li onClick={IsMobileDevice?drawerToggle:() => {}}><Link to="#">Pricing</Link></li> */}
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><ExternalLink href="https://bitcoinmat.atlassian.net/servicedesk/customer/portal/8" target="_blank">{i18next.t('sidebar_claim_form')}</ExternalLink></li>
                                            {/* <li onClick={IsMobileDevice?drawerToggle:() => {}}><Link to="/Files" target="_blank">Files</Link></li> */}
                                        </ul>
                                    </li>
                                    <li className={MenuName === 'messages' ? 'active messages submenu' : 'messages submenu'} onClick={(event) => setMenuName("messages")}><Link href="#">{i18next.t('sidebar_messages')}</Link>
                                        <ul>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/messages">{i18next.t('sidebar_messages')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><Link to="/News">{i18next.t('news')}</Link></li>
                                            <li onClick={IsMobileDevice ? drawerToggle : () => { }}><ExternalLink href="https://bitcoinmat.atlassian.net/servicedesk/customer/portal/8" target="_blank" >{i18next.t('ticket')}</ExternalLink></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                        : ''}

                </PerfectScrollbar>
            }


        </React.Fragment>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                classes={{ paper: classes.drawerPaperLight }}
                ModalProps={{ keepMounted: true }}
            >
                {drawer}
            </Drawer>
        </nav>
    );
};

export default MainLayout;
