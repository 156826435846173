/* eslint-disable */
import React, { lazy, Suspense, useState, useEffect } from 'react';
import axios from 'axios';
import { Switch, Route, useLocation, Redirect, useHistory } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
// import { useSelector } from 'react-redux';
import Loader from './component/Loader/Loader';
import NavMotion from './layout/NavMotion';
import MainLayout from './layout/MainLayout';
// import MainLayoutFrontend from './layout/MainLayout/FrontendIndex';
import MinimalLayout from './layout/MinimalLayout';

const AuthLogin = lazy(() => import('./views/Login'));
const LoginEmailVerify = lazy(() => import('./views/Login/LoginEmailVerify'));
const LoginPhoneVerify = lazy(() => import('./views/Login/LoginPhoneVerify'));
const userInfo = lazy(() => import('./views/Login/UserInfo'));
const AuthRegister = lazy(() => import('./views/Register'));
const AuthCompleteregistration = lazy(() => import('./views/Register/Completeregistration'));
const AuthForgetpassword = lazy(() => import('./views/Forgetpassword'));
const ForgetChangePassword = lazy(() => import('./views/Forgetpassword/ForgetChangePassword'));
const ForgetGoogleAuthenticator = lazy(() => import('./views/ForgetGoogleAuthenticator'));
const Verify2FAOTP = lazy(() => import('./views/ForgetGoogleAuthenticator/Verify2FAOTP'));
const JiraNote = lazy(() => import('./views/ForgetGoogleAuthenticator/JiraNote'));

const Auth = lazy(() => import('./views/Auth'));
const Otp = lazy(() => import('./views/Otp'));
const AuthEnabled = lazy(() => import('./views/AuthEnabled'));
const Googleauthenticator = lazy(() => import('./views/Googleauthenticator'));
const Editprofile = lazy(() => import('./views/Profile/Editprofile'));
const Changepassword = lazy(() => import('./views/Changepassword'));
const Maintain = lazy(() => import('./views/Error/Maintain'));
const Summary = lazy(() => import('./views/Profile/Summary'));
const Kyc = lazy(() => import('./views/Profile/Kyc'));
const Pricings = lazy(() => import('./views/Profile/Pricings'));

// const Price = lazy(() => import('./views/Application/Price/Price2'));

const DashboardDefault = lazy(() => import('./views/Dashboard/Default'));

const Cardmanagement = lazy(() => import('./views/Cardmanagement'));
// const createcard = lazy(() => import('./views/Cardmanagement'));
// const viewcard = lazy(() => import('./views/Cardmanagement/Viewcard'));
// const editcard = lazy(() => import('./views/Cardmanagement/Editcard'));

const TwoSecurity = lazy(() => import('./views/TwoSecurity'));
const Transactions = lazy(() => import('./views/Transactions'));
const Transferbetweenaccounts = lazy(() => import('./views/Transactions/Transferbetweenaccounts'));
const Transferbetweenusers = lazy(() => import('./views/Transactions/Transferbetweenusers'));
const Incomingwiretransfer = lazy(() => import('./views/Transactions/Incomingwiretransfer'));
const Cardfundingtransfer = lazy(() => import('./views/Transactions/Cardfundingtransfer'));
const Outgoingwiretransfer = lazy(() => import('./views/Transactions/Outgoingwiretransfer'));
const Transactions_box = lazy(() => import('./views/Transactions/Transactions_box'));
const Transactions_crypto_exchange = lazy(() => import('./views/Transactions/Transactions_crypto_exchange'));
const Transactions_cash_order = lazy(() => import('./views/Transactions/Transactions_cash_order'));
const Transactions_crypto_earning = lazy(() => import('./views/Transactions/Transactions_crypto_earning'));
const Transactions_crypto_deposit = lazy(() => import('./views/Transactions/Transactions_crypto_deposit'));
const Transactions_crypto_withdraw = lazy(() => import('./views/Transactions/Transactions_crypto_withdraw'));
const Transactions_crypto_credit = lazy(() => import('./views/Transactions/Transactions_crypto_credit'));
const Invoice = lazy(() => import('./views/Transactions/Invoice'));
const InvoiceWithdraw = lazy(() => import('./views/Transactions/InvoiceWithdraw'));

const News = lazy(() => import('./views/News'));
const Newsdetailss = lazy(() => import('./views/News/Newsdetailss'));
const Specificaccountstatement = lazy(() => import('./views/Report/Specificaccountstatement'));
const Allaccountstransactions = lazy(() => import('./views/Report/Allaccountstransactions'));
const Allaccountsbalances = lazy(() => import('./views/Report/Allaccountsbalances'));
const Settings = lazy(() => import('./views/Profile/Settings'));
const Files = lazy(() => import('./views/Profile/Files'));
const Transferdetails = lazy(() => import('./views/Transactions/Transferdetails'));
const Transfersuccessfully = lazy(() => import('./views/Transactions/Transfersuccessfully'));
const Incomingmessage = lazy(() => import('./views/Message/Incomingmessage'));
const Readmessage = lazy(() => import('./views/Message/Readmessage'));
const Newmessage = lazy(() => import('./views/Message/Newmessage'));
const Outgoingmessage = lazy(() => import('./views/Message/Outgoingmessage'));
const Profilebox = lazy(() => import('./views/Profile/'));
const Message = lazy(() => import('./views/Message'));
const Broadcast = lazy(() => import('./views/Broadcast'));
const BroadcastDetails = lazy(() => import('./views/Broadcast/BroadcastDetails'));

const accountbox = lazy(() => import('./views/Account/accountbox'));
const Account = lazy(() => import('./views/Account'));
const Accountdetails = lazy(() => import('./views/Account/Accountdetails'));

const Accountboxes = lazy(() => import('./views/Account/Accountboxes'));
const Accountboxes_credit_account = lazy(() => import('./views/Account/Accountboxes_credit_account'));
const Accountboxes_crypto_account = lazy(() => import('./views/Account/Accountboxes_crypto_account'));
const Accountboxes_earning_account = lazy(() => import('./views/Account/Accountboxes_earning_account'));
const Accountboxes_earning_account_staking = lazy(() => import('./views/Account/Accountboxes_earning_account_staking'));
const Accountboxes_earning_account_purchasing = lazy(() => import('./views/Account/Accountboxes_earning_account_purchasing'));
const Accountboxes_cash_account = lazy(() => import('./views/Account/Accountboxes_cash_account'));

const Dashboard_new = lazy(() => import('./views/Account/Dashboard_new'));

const JiraMessages = lazy(() => import('./views/JiraMessages'));
const CreateIssue = lazy(() => import('./views/JiraMessages/CreateIssue'));
const OpenIssue = lazy(() => import('./views/JiraMessages/OpenIssue'));



const RtlLayout = lazy(() => import('./views/RtlLayout'));

const TableBasic = lazy(() => import('./views/Tables/TableBasic'));

const UtilsIcons = lazy(() => import('./views/Utils/Icons'));
const UtilsTypography = lazy(() => import('./views/Utils/Typography'));

const MultiLanguage = lazy(() => import('./views/MultiLanguage'));


const Kiosk = lazy(() => import('./views/Frontend/Kiosk'));


const Home = lazy(() => import('./views/Frontend/Home'));
const Terms = lazy(() => import('./views/Frontend/Terms'));
const Privacy = lazy(() => import('./views/Frontend/Privacy'));
const PricingPlan = lazy(() => import('./views/Frontend/PricingPlan'));
const Help_center = lazy(() => import('./views/Frontend/Help_center'));
const Crypto_Voucher = lazy(() => import('./views/Frontend/Product/Crypto_Voucher'));
const Buy_Crypto_via_Card = lazy(() => import('./views/Frontend/Product/Buy_Crypto_via_Card'));
const Crypto_to_Crypto_Exchange = lazy(() => import('./views/Frontend/Product/Crypto_to_Crypto_Exchange'));
const Bitkasa = lazy(() => import('./views/Frontend/Product/Bitkasa'));
const Cash_Withdrawals_Bitcoin_ATMs = lazy(() => import('./views/Frontend/Product/Cash_Withdrawals_Bitcoin_ATMs'));
const Bitcoin_ATMs = lazy(() => import('./views/Frontend/Product/Bitcoin_ATMs'));
const Bitcoin_ATMs_Details = lazy(() => import('./views/Frontend/Product/Bitcoin_ATMs_Details'));
const Bitcoinmat_ATM_Details = lazy(() => import('./views/Frontend/Product/Bitcoinmat_ATM_Details'));
const Bitcoin_ATMs_Details_new = lazy(() => import('./views/Frontend/Product/Bitcoin_ATMs_Details_new'));

const Crypto_to_Crypto = lazy(() => import('./views/Frontend/Home/Crypto_to_Crypto'));
const Crypto_to_Crypto_Success = lazy(() => import('./views/Frontend/Home/Crypto_to_Crypto_Success'));

const Bitcoin_Base = lazy(() => import('./views/Frontend/Services/Bitcoin_Base'));
const Crypto_Merch = lazy(() => import('./views/Frontend/Services/Crypto_Merch'));

const Transaction_Check = lazy(() => import('./views/Frontend/Services/Transaction_Check'));
const Crypto_rates = lazy(() => import('./views/Frontend/Services/Crypto_rates'));
const Blog_Page = lazy(() => import('./views/Frontend/Services/Blog_Page'));
const BlogDetails = lazy(() => import('./views/Frontend/Services/BlogDetails'));
const TheyWroteAboutUs = lazy(() => import('./views/Frontend/Services/TheyWroteAboutUs'));
const TheyWroteAboutUsDetails = lazy(() => import('./views/Frontend/Services/TheyWroteAboutUsDetails'));


const Map = lazy(() => import('./views/Frontend/Terms/map'));


const Routes = ({ selectedLanguage }) => {
    const location = useLocation();
    const history = useHistory();
    // const usersdata = useSelector((state) => state.usersdata);
    const [auth, set_auth] = useState(true);
    const [sitemap, setSitemap] = useState("sitemap-org.xml");

    
    const handleLoadUserData = () => {
        const headers = {
            'Content-Type': 'application/json',
            'bcbqtoken': process.env.REACT_APP_SECRET_TOKEN,
            'accesstoken': 'Bearer ' + window.localStorage.getItem("accesstoken"),
            'lang': window.localStorage.getItem("language"),
        }
        axios.get(process.env.REACT_APP_API_URL + 'user/v1/viewuser/' + window.localStorage.getItem("u_id"),
            { headers: headers }
        )
        .then(function (res) {
            res = res.data;
            if (res.code === "200") {}
            else {
                if(res.message.toLowerCase() === "access token not match!") {
                    const locationPathnameArr = window.location.pathname.split("/");
                    const currentPath = locationPathnameArr[1];
                    const routesArr = [
                        'summary',
                        'dashboard',
                        'cardmanagement',
                        'createcard',
                        'editcard',
                        'viewcard',
                        'editprofile',
                        'changepassword',
                        'twosecurity',
                        'transactions',
                        'transferbetweenaccounts',
                        'transferbetweenusers',
                        'incomingwiretransfer',
                        'cardfundingtransfer',
                        'outgoingwiretransfer',
                        'pricing',
                        'transactions_box',
                        'transactions_crypto_exchange',
                        'transactions_cash_order',
                        'transactions_crypto_earning',
                        'transactions_crypto_deposit',
                        'transactions_crypto_withdraw',
                        'transactions_crypto_credit',
                        'account',
                        'accountdetails',
                        'accountboxes',
                        'accountboxes_credit_account',
                        'accountboxes_crypto_account',
                        'accountboxes_earning_account',
                        'accountboxes_earning_account_staking',
                        'accountboxes_earning_account_purchasing',
                        'accountboxes_cash_account',
                        'dashboard_new',
                        'jiramessages',
                        'createissue',
                        'openissue',
                        'news',
                        'newsdetailss',
                        'specificaccountstatement',
                        'allaccountstransactions',
                        'allaccountsbalances',
                        'kyc',
                        'settings',
                        'files',
                        'transferdetails',
                        'transfersuccessfully',
                        'incomingmessage',
                        'outgoingmessage',
                        'readmessage',
                        'newmessage',
                        'profilebox',
                        'message',
                        'accountbox',
                        'messages',
                        'messagedetails',
                        'map',
                    ];
                    const isInRoutesArr = routesArr.includes(currentPath.toLowerCase());
                    if(isInRoutesArr) {
                        history.push('/login');
                    }
                }
            }
        })
        .catch(function (error) {

        });
    }

    const homeRoutesArr = [
        "Home",
        // "Homesl",
        // "Homecz",
        // "Homegr",
    ];
    const homeRoutes = homeRoutesArr.map((route, index) => (
        <Route key={index} exact path={"/"+route} component={Home} />
    ));

    const bitcoinATMsRoutesArr = [
        "Bitcoin_ATMs",
        "Bitcoinmaty",
        "Bitcoin_geldautomat",
    ];
    const bitcoinATMsRoutes = bitcoinATMsRoutesArr.map((route, index) => (
        <Route key={index} exact path={"/"+route} component={Bitcoin_ATMs} />
    ));

    const cryptoToCryptoExchangeRoutesArr = [
        "Crypto_to_crypto_swap",
        "Krypto_za_krypto_vymena",
        "Krypto_zu_krypto_austausch",
        "kriptovaluta_kriptovaluta_cserejehez",
    ];
    const cryptoToCryptoExchangeRoutes = cryptoToCryptoExchangeRoutesArr.map((route, index) => (
        <Route key={index} exact path={"/"+route} component={Crypto_to_Crypto_Exchange} />
    ));

    const cryptoVoucherRoutesArr = [
        "Crypto_gift_voucher",
        "Krypto_darcekovy_poukaz",
        "Krypto_darkovy_poukaz",
        "Krypto_geschenkgutschein",
        "kripto_ajandekkartya",
    ];
    const cryptoVoucherRoutes = cryptoVoucherRoutesArr.map((route, index) => (
        <Route key={index} exact path={"/"+route} component={Crypto_Voucher} />
    ));

    useEffect(() => {
        handleLoadUserData();
        if (window.localStorage.getItem("temp_u_id") !== null) {
            set_auth(false);
        }

        const locationPathnameArr = window.location.pathname.split("/");
        const currentPath = locationPathnameArr[1];
        const routesArr = [
            ...homeRoutesArr,
            ...bitcoinATMsRoutesArr,
            ...cryptoToCryptoExchangeRoutesArr,
            ...cryptoVoucherRoutesArr,
            'kiosk',
            'terms',
            'privacy',
            'pricing',
            'help_center',
            'crypto_delivery',
            'bitkasa',
            'cash_withdrawals_bitcoin_atms',
            'bitcoin_atms_details',
            'bitcoinmat',
            'bitcoin_atms_details_new',
            'crypto-to-crypto',
            'crypto-to-crypto-success',
            'bitcoin_base',
            'crypto_merch',
            'transaction_check',
            'crypto_rates',
            'blog_page',
            'blog',
            'they-wrote-about-us',
            'they-wrote-about-us-details',
            'login',
            'loginemailverify',
            'loginphoneverify',
            'register',
            'register',
            'forgetpassword',
            'forgetchangepassword',
            'forgetgoogleauthenticator',
            'verify2faotp',
            'jiranote',
            'otp',
            'authenabled',
            'googleauthenticator',
            'auth',
            'completeregistration',
            'invoice',
            'invoicewithdraw',
            'userinfo',
            'summary',
            'dashboard',
            'cardmanagement',
            'createcard',
            'editcard',
            'viewcard',
            'editprofile',
            'changepassword',
            'twosecurity',
            'transactions',
            'transferbetweenaccounts',
            'transferbetweenusers',
            'incomingwiretransfer',
            'cardfundingtransfer',
            'outgoingwiretransfer',
            'pricing',
            'transactions_box',
            'transactions_crypto_exchange',
            'transactions_cash_order',
            'transactions_crypto_earning',
            'transactions_crypto_deposit',
            'transactions_crypto_withdraw',
            'transactions_crypto_credit',
            'account',
            'accountdetails',
            'accountboxes',
            'accountboxes_credit_account',
            'accountboxes_crypto_account',
            'accountboxes_earning_account',
            'accountboxes_earning_account_staking',
            'accountboxes_earning_account_purchasing',
            'accountboxes_cash_account',
            'dashboard_new',
            'jiramessages',
            'createissue',
            'openissue',
            'news',
            'newsdetailss',
            'specificaccountstatement',
            'allaccountstransactions',
            'allaccountsbalances',
            'kyc',
            'settings',
            'files',
            'transferdetails',
            'transfersuccessfully',
            'incomingmessage',
            'outgoingmessage',
            'readmessage',
            'newmessage',
            'profilebox',
            'message',
            'accountbox',
            'messages',
            'messagedetails',
            'map',
            'sitemap.xml',
        ];
        const lowerCaseRoutesArr = routesArr.map(route => route.toLowerCase());
        const currentPathLowerCase = currentPath.toLowerCase();
        const isInRoutesArr = lowerCaseRoutesArr.includes(currentPathLowerCase);
        if (!isInRoutesArr) {
            history.push("/Home");
        }

        let HostName = window.location.hostname;
        if (HostName.indexOf("www") !== -1) {
            HostName = HostName.replace(/^www\./, "");
        }
        if(process.env.REACT_APP_ENV === "production") {
            switch (HostName) {
                case "bitcoinmat.sk":
                  setSitemap("sitemap-sk.xml");
                break;

                case "bitcoinmat.gr":
                  setSitemap("sitemap-gr.xml");
                break;

                case "bitcoinmat.hu":
                  setSitemap("sitemap-hu.xml");
                break;

                case "bitcoinmat.at":
                  setSitemap("sitemap-at.xml");
                break;

                case "bitcoinmat.pl":
                  setSitemap("sitemap-pl.xml");
                break;

                default:
                  setSitemap("sitemap-org.xml");
                break;
            }
        }
    }, []);

    const RedirectComponent = () => {
        let FullURL = window.location.href;
        if (FullURL.indexOf("sitemap.xml") !== -1) {
            let FullURLSplit = FullURL.split("sitemap.xml");
            window.location.href = FullURLSplit[0] + sitemap;
        }
        return null;
    };

    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
                <Switch>
                    {/* <Route exact path="/kiosk" component={Kiosk} /> */}

                    <Route exact path="/" component={Home} />
                    <Route exact path="/sitemap.xml" component={RedirectComponent} />

                    {homeRoutes}
                    {bitcoinATMsRoutes}
                    {cryptoToCryptoExchangeRoutes}
                    {cryptoVoucherRoutes}

                    <Route exact path="/Terms" component={Terms} />
                    <Route exact path="/Privacy" component={Privacy} />
                    <Route exact path="/Pricing-Plan" component={PricingPlan} />
                    <Route exact path="/Help_center" component={Help_center} />
                    <Route exact path="/Crypto_Delivery" component={Buy_Crypto_via_Card} />
                    <Route exact path="/Bitkasa" component={Bitkasa} />
                    <Route exact path="/Cash_Withdrawals_Bitcoin_ATMs" component={Cash_Withdrawals_Bitcoin_ATMs} />
                    <Route exact path="/Bitcoin_ATMs_Details/:id" component={Bitcoin_ATMs_Details} />
                    <Route exact path="/bitcoinmat/:descriptionid" component={Bitcoinmat_ATM_Details} />
                    <Route exact path="/Bitcoin_ATMs_Details_new" component={Bitcoin_ATMs_Details_new} />
                    <Route exact path="/crypto-to-crypto" component={Crypto_to_Crypto} />
                    <Route exact path="/crypto-to-Crypto-success" component={Crypto_to_Crypto_Success} />

                    <Route exact path="/Bitcoin_Base" component={Bitcoin_Base} />
                    <Route exact path="/Crypto_Merch" component={Crypto_Merch} />
                    <Route exact path="/Transaction_Check" component={Transaction_Check} />
                    <Route exact path="/Crypto_rates" component={Crypto_rates} />
                    <Route exact path="/blog" component={Blog_Page} />
                    <Route exact path="/blog/:id" component={BlogDetails} />
                    <Route exact path="/they-wrote-about-us" component={TheyWroteAboutUs} />
                    <Route exact path="/they-wrote-about-us-details/:id" component={TheyWroteAboutUsDetails} />

                    <Route exact path="/login" component={AuthLogin} />
                    <Route exact path="/loginemailverify" component={LoginEmailVerify} />
                    <Route exact path="/loginphoneverify" component={LoginPhoneVerify} />
                    <Route exact path="/register" component={AuthRegister} />
                    <Route exact path="/register/:plan_type" component={AuthRegister} />
                    <Route exact path="/forgetpassword" component={AuthForgetpassword} />
                    <Route exact path="/forgetchangepassword/:id" component={ForgetChangePassword} />
                    <Route exact path="/forgetgoogleauthenticator" component={ForgetGoogleAuthenticator} />
                    <Route exact path="/verify2faotp" component={Verify2FAOTP} />
                    <Route exact path="/jiranote" component={JiraNote} />
                    <Route exact path="/otp" component={Otp} />
                    <Route exact path="/authenabled" component={AuthEnabled} />
                    <Route exact path="/googleauthenticator" component={Googleauthenticator} />
                    <Route exact path="/auth" component={Auth} />
                    <Route exact path="/completeregistration" component={AuthCompleteregistration} />
                    <Route exact path="/invoice/:id" component={Invoice} />
                    <Route exact path="/invoicewithdraw/:id" component={InvoiceWithdraw} />
                    <Route exact path="/userinfo" component={userInfo} />
                    

                    <Route exact path="/kiosk" component={Map} />


                    <Route path={[]}>
                        <MinimalLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion></NavMotion>
                            </Switch>
                        </MinimalLayout>
                    </Route>
                    

                    { auth ?
                    <MainLayout>
                        <Switch location={location} key={location.pathname}>
                            <NavMotion>

                                {/* <Route exact path="/register" component={Price} />
                                <Route exact path="/application/forgot-password" component={Price} />
                                <Route exact path="/forgot-password" component={Price} />
                                <Route exact path="/pages/error/error1" component={Price} />
                                <Route exact path="/pages/error/error2" component={Price} />
                                <Route exact path="/pages/comingsoon" component={Price} /> */}

                                <Route exact path="/Summary" component={Summary} />
                                {/* <Route exact path="/Summary">
                                    <Summary selectedLanguage={SelectedLanguage} />
                                </Route> */}
                                <Route exact path="/dashboard" component={DashboardDefault} />
                                <Route exact path="/cardmanagement" component={Cardmanagement} />
                                {/* <Route exact path="/createcard" component={createcard} /> */}
                                {/* <Route exact path="/editcard" component={editcard} /> */}
                                
                                {/* <Route exact path="/viewcard" component={viewcard} /> */}
                                <Route exact path="/Editprofile" component={Editprofile} />
                                <Route exact path="/changepassword" component={Changepassword} />
                                <Route exact path="/TwoSecurity" component={TwoSecurity} />
                                <Route exact path="/Transactions" component={Transactions} />
                                <Route exact path="/Transferbetweenaccounts" component={Transferbetweenaccounts} />
                                <Route exact path="/Transferbetweenusers" component={Transferbetweenusers} />
                                <Route exact path="/Incomingwiretransfer" component={Incomingwiretransfer} />
                                <Route exact path="/Cardfundingtransfer" component={Cardfundingtransfer} />
                                <Route exact path="/Outgoingwiretransfer" component={Outgoingwiretransfer} />
                                <Route exact path="/Pricing" component={Pricings} />

                                <Route exact path="/Transactions_box" component={Transactions_box} />
                                <Route exact path="/Transactions_crypto_exchange" component={Transactions_crypto_exchange} />
                                <Route exact path="/Transactions_cash_order" component={Transactions_cash_order} />
                                <Route exact path="/Transactions_crypto_earning" component={Transactions_crypto_earning} />
                                <Route exact path="/Transactions_crypto_deposit" component={Transactions_crypto_deposit} />
                                <Route exact path="/Transactions_crypto_withdraw" component={Transactions_crypto_withdraw} />
                                <Route exact path="/Transactions_crypto_credit" component={Transactions_crypto_credit} />
                                

                                <Route exact path="/Account" component={Account} />
                                <Route exact path="/Accountdetails/:id" component={Accountdetails} />
                                <Route exact path="/Accountboxes" component={Accountboxes} />
                                <Route exact path="/Accountboxes_credit_account" component={Accountboxes_credit_account} />
                                <Route exact path="/Accountboxes_crypto_account" component={Accountboxes_crypto_account} />
                                <Route exact path="/Accountboxes_earning_account" component={Accountboxes_earning_account} />
                                <Route exact path="/Accountboxes_earning_account_staking" component={Accountboxes_earning_account_staking} />
                                <Route exact path="/Accountboxes_earning_account_purchasing" component={Accountboxes_earning_account_purchasing} />
                                <Route exact path="/Accountboxes_cash_account" component={Accountboxes_cash_account} />

                                <Route exact path="/Dashboard_new" component={Dashboard_new} />

                                <Route exact path="/jiramessages" component={JiraMessages} />
                                <Route exact path="/createissue" component={CreateIssue} />
                                <Route exact path="/openissue/:id" component={OpenIssue} />
                                
                                <Route exact path="/News" component={News} />
                                <Route exact path="/Newsdetailss/:id" component={Newsdetailss} />
                                <Route exact path="/Specificaccountstatement" component={Specificaccountstatement} />
                                <Route exact path="/Allaccountstransactions" component={Allaccountstransactions} />
                                <Route exact path="/Allaccountsbalances" component={Allaccountsbalances} />
                                <Route exact path="/Kyc" component={Kyc} />
                                <Route exact path="/Settings" component={Settings} />
                                <Route exact path="/Files" component={Files} />
                                <Route exact path="/Transferdetails" component={Transferdetails} />
                                <Route exact path="/Transfersuccessfully" component={Transfersuccessfully} />
                                <Route exact path="/Incomingmessage" component={Incomingmessage} />
                                <Route exact path="/Outgoingmessage" component={Outgoingmessage} />
                                <Route exact path="/Readmessage/:id" component={Readmessage} />
                                <Route exact path="/Newmessage" component={Newmessage} />
                                <Route exact path="/Profilebox" component={Profilebox} />
                                <Route exact path="/Message" component={Message} />
                                <Route exact path="/accountbox" component={accountbox} />
                                <Route exact path="/messages" component={Broadcast} />
                                <Route exact path="/messagedetails/:id" component={BroadcastDetails} />
                                
                                <Route exact path="/maintenance" component={Maintain} />
                                

                                <Route path="/utils/util-typography" component={UtilsTypography} />
                                <Route path="/multi-language" component={MultiLanguage} />
                                <Route path="/rtl" component={RtlLayout} />
                                <Route path="/utils/util-icons" component={UtilsIcons} />
                                <Route path="/tables/table-basic" component={TableBasic} />


                            </NavMotion>
                        </Switch>
                    </MainLayout>
                    : 
                        <Redirect to='/login' />
                    }

                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
