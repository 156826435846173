import * as types from "../store/actionType";

const initialState = {
    users: [],
    user: {},
    loading: true,
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_USER_DATA:
            return {
                ...state,
                users: action.payload,
                    loading: false,
            };
        default:
            return state;
    }
};

export default userReducer;