/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { makeStyles, useMediaQuery, useTheme, AppBar, CssBaseline, Toolbar } from '@material-ui/core';
import { drawerWidth } from './../../store/constant';
import Header from './Header';
import Sidebar from './Sidebar';
import CryptoJS from 'crypto-js';
import i18next from 'i18next';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    toolbar: theme.mixins.toolbar,
    content: {
        width: '100%',
        minHeight: '100vh',
        flexGrow: 1,
        /*padding: theme.spacing(3),*/
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    main: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    header: {
        zIndex: 1201,
    },
}));


const MainLayout = ({children, selectedLanguage, onLanguageChange}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [auth_enabled, set_auth_enabled] = React.useState(true);
    const [userInfo] = React.useState(window.localStorage.getItem("full_name") + "," + window.localStorage.getItem("company_name") + "," + window.localStorage.getItem("file_path") + "," + window.localStorage.getItem("user_image"));

    
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };
    

    const [SelectedLanguage, setSelectedLanguage] = useState('en');
    function handleLanguageChange(lang) {
        setSelectedLanguage(lang);
        onLanguageChange(lang);
    }


    React.useEffect(() => {
        setDrawerOpen(matchUpMd);

        if (window.localStorage.getItem("temp_u_id") !== null)
        set_auth_enabled(false);
    }, [matchUpMd]);


    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.header}>
                <Toolbar>
                    <Header drawerOpen={drawerOpen} drawerToggle={handleDrawerToggle} onLanguageChange={handleLanguageChange} selectedLanguage={selectedLanguage} />
                </Toolbar>
            </AppBar>
            {auth_enabled ?
            // eslint-disable-next-line no-sequences
            <Sidebar name={userInfo} drawerOpen={drawerOpen} drawerToggle={handleDrawerToggle} />
            : '' }
            <main className={clsx(classes.content, { [classes.contentShift]: drawerOpen })}>
                <div className={classes.toolbar} />
                <div className={classes.main+ ' rm-div'}>{children}</div>
            </main>
        </div>
    );
};

export default MainLayout;
